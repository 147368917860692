<template>
  <div>
    <div class="barra-estado"></div>
    <section class="hero is-danger is-fullheight">
      <div class="hero-body lfondo">
        <div class="container has-text-centered">
          <figure class="image is-squere is-inline-block preview-page-image">
            <img class="" src="assets/logo/sanofi-login.png" />
          </figure>
        </div>
      </div>
    </section>
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/login">Login</router-link>
    </div>
    <div class="home">
      <img alt="Vue logo" src="../assets/logo.png" />
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Root",
  components: {},
  mounted() {
    let sessionExists = this.$session.exists();
    if (!sessionExists) this.$router.replace({ name: "Login" });
    else this.$router.replace({ name: "Home" });
  },
};
</script>
